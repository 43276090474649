
import React from 'react'

import Meta from 'layout/Meta'


export default function PrivacyPage() {
	return <main>

		<Meta title="Privacy Policy" />
		
		<h1>Privacy Policy</h1>

		<p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.</p>

		<p>When you sign in using any third-party platforms (e.g. Twitter or Facebook) we only access and store information visible in the chat room.</p>

		<p>The messages in chat are 100% anonymous and don't contain any reference to the profile they were created with. This can be verified by looking at the client-side source code.</p>
		
		<p>We use <em>Firebase</em> for all authentication, live database, and hosting.</p>

		<p>We don’t share any information publicly or with third-parties.</p>

		<p>Our billing is operated by <a href='https://stripe.com/'>Stripe</a>. <em>Nameless</em> does not store any of the personal information you provide when processing payments.</p>

		<p>We use Google Analytics to monitor and analyze the use of our service but we use it in a GDPR compliant mode, i.e. we do not track users, we do not share the data, we anonymize each visit, and we respect your Do Not Track settings. For more information on the privacy practices of Google, please visit the <a href='https://policies.google.com/privacy?hl=en'>Google Privacy & Terms web page</a>.</p>

		<p>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us at contact@nameless.im.</p>

		<p>This policy is effective as of September 1st 2021.</p>

	</main>
}

